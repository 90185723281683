<template>
  <base-section id="what-is-happening" class="secondary bac-section">
    <base-section-heading
      class="white--text"
      color="white"
      title="What is happening?"
    ></base-section-heading>
    <v-responsive class="mx-auto" max-width="1200">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="(card, i) in cards"
            :key="i"
            cols="12"
            sm="6"
            md="6"
            class="px-8"
          >
            <base-info-card align="center" dark v-bind="card" />
            <div class="pt-2">
              <v-img v-if="card.secImg" :src="`img/${card.secImg}`"></v-img>
            </div>
            <div class="white--text pt-2" style="text-align: center;" v-if="card.footnote" v-html="card.footnote"></div>
            <hr v-if="i < cards.length - 1" class="d-flex d-sm-none" style="border: none; border-bottom: 1px solid white; margin: 60px 0 40px;" />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
export default {
  name: "WhatIsHappening",

  data: () => ({
    cards: [
      {
        // icon: "mdi-office-building",
        image: "site-sbf.jpg",
        title: "Ship Storage Facility",
        text:
          "This massive new building will replace the current historical brick building, taking up 9,500 additional square feet, and will be 35 feet tall (nearly twice as tall as it is right now)",
          secImg: "now-then-foundry.jpg"
      },
      /* {
        // icon: "mdi-domain",
        image: "site-ob.jpg",
        title: "Operations Building",
        text:
          "This new 2,800 square foot building will actually be built out on the existing dock, interfering with the ability for the public to see or access the water",
        footnote: "<em>Note: The Marina now says they are scrapping this part of the project. We have requested the updated plans so we can verify it has been removed from them. Once we receive the plans, we'll update the website.</em>",
          secImg: "now-then-dock.jpg"
      }, */
      {
        // icon: "mdi-fuel",
        image: "site-f.jpg",
        title: "Fuel Storage Area",
        text:
          "Two above-ground fuel tanks <s>are being</s> <u>have been</u> installed that sit right across the street from a school, with underground pipes running the length of the&nbsp;marina",
          secImg: "now-then-fuel.jpg"
      }
    ]
  })
};
</script>
